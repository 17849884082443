import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import React Helmet
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Icons for arrows
/*service photos*/
import ABS from '../assets/ABS.png';
import ALLF from '../assets/AllFactory.png';
import ALT from '../assets/Alternator.png';
import Battery from '../assets/battery.jpg';
import Brake from '../assets/BrakeF.jpg';
import Coolant from '../assets/coolant.jpg';
import Differ from '../assets/Differential.png';
import ElectD from '../assets/ElectricalDiag.png';
import EngineF from '../assets/EngineFlush.png';
import Fuel from '../assets/Fuel.png';
import Oil from '../assets/OilChange.jpg';
import Parasitic from '../assets/Parasitic.png';
import Power from '../assets/Power.png';
import Radiator from '../assets/Radiator.png';
import SmokeT from '../assets/SmokeTest.jpg';
import Timing from '../assets/TimingBelt.jpg';
import TransferC from '../assets/TransferCase.png';
import TransF from '../assets/TransmissionFlush.png';
import TuneUp from '../assets/TuneUps.png';
import WaterP from '../assets/WaterP.png';

const Services = () => {
  const serviceScrollRef = useRef(null); // Reference for scrolling

  // Function to scroll left
  const scrollLeft = () => {
    if (serviceScrollRef.current) {
      serviceScrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  // Function to scroll right
  const scrollRight = () => {
    if (serviceScrollRef.current) {
      serviceScrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  // List of services with their details and corresponding images
  const serviceDetails = [
    { title: 'Transfer Case', description: 'Transfer case repairs, maintenance, and fluid services for smooth drivetrain performance.', link: '/services/transfer-case', image: TransferC },
    { title: 'Differential Service', description: 'Differential repairs, maintenance, and fluid changes for enhanced drivetrain efficiency.', link: '/services/diff-service', image: Differ },
    { title: 'Oil Change', description: 'Quick and reliable oil changes to keep your engine running smoothly.', link: '/services/oil-change', image: Oil },
    { title: 'Battery Replacement', description: 'Our battery replacement service ensures your car starts every time, with top-quality batteries.', link: '/services/battery-replacement', image: Battery },
    { title: 'Smoke Test', description: 'Smoke testing to detect leaks and ensure your vehicle’s system is airtight.', link: '/services/smoke-test', image: SmokeT },
    { title: 'Transmission Flush', description: 'Transmission flushes to maintain smooth shifting and optimal performance.', link: '/services/transmission-flush', image: TransF },
    { title: 'Check Engine Light', description: 'Check engine light, alternator repair, diagnostics for modern vehicles.', link: '/services/electrical-diagnostics', image: ElectD },
    { title: 'All Factory Maintenance', description: 'Comprehensive factory maintenance services for optimal vehicle performance.', link: '/services/all-factory', image: ALLF },
    { title: 'ABS-System', description: 'ABS system diagnostics and repairs to ensure safe braking performance.', link: '/services/abs-system', image: ABS },
    { title: 'Timing Belt Replacement', description: 'Replacing your timing belt prevents engine damage and ensures smooth operation.', link: '/services/timing-belt-replacement', image: Timing },
    { title: 'Tune Ups', description: 'Comprehensive tune-ups to keep your engine running at peak performance.', link: '/services/tune-ups', image: TuneUp },
    { title: 'Parasitic Draw', description: 'Parasitic draw diagnostics to identify and fix battery-draining issues.', link: '/services/parasitic-draw', image: Parasitic },
    { title: 'Alternator Replacement', description: 'Alternator replacement to ensure reliable charging and electrical system performance.', link: '/services/alternator-replacement', image: ALT },
    { title: 'Radiator Replacemnet', description: 'Radiator replacement to prevent overheating and ensure efficient engine cooling.', link: '/services/radiator-replacement', image: Radiator },
    { title: 'Water Pump', description: 'Water pump replacement to maintain proper coolant circulation and engine temperature.', link: '/services/water-pump', image: WaterP },
    { title: 'Power Steering Flush', description: 'Power steering flush to maintain smooth and responsive steering performance.', link: '/services/power-steering-flush', image: Power },
    { title: 'Engine Flush', description: 'Engine flush to remove sludge and improve overall engine efficiency.', link: '/services/engine-flush', image: EngineF },
    { title: 'Fuel Injection Service', description: 'Fuel injection cleaning to enhance fuel efficiency and engine performance.', link: '/services/fuel-injection', image: Fuel },
    { title: 'Brake Flush', description: 'Brake fluid flush to ensure optimal braking performance and system longevity.', link: '/services/brake-flush', image: Brake },
    { title: 'Coolant Flush', description: 'Coolant flush to maintain proper engine temperature and prevent overheating.', link: '/services/coolant-flush', image: Coolant },
  ];

  return (
    <section className="bg-transparent text-white min-h-screen flex flex-col justify-center items-center px-4 sm:px-8 relative z-10 pt-20">
          {/* SEO Section using React Helmet */}
        <Helmet>
          <title>Smog Check & Auto Repair Services in Temecula | Advanced Smog</title>
          <meta name="description" content="Need a smog check or auto repair in Temecula? We offer emissions testing, oil changes, diagnostics, and maintenance for all vehicles, including high-end cars. Visit Advanced Smog today!" />
          <meta name="keywords" content="smog check near me, auto repair Temecula, smog test, car diagnostics, emissions test, luxury car maintenance, oil change, brake service, transmission repair, high-end vehicle service, Temecula auto repair shop" />
              {/* Open Graph Metadata */}
          <meta property="og:title" content="Smog Check & Auto Repair Services in Temecula | Advanced Smog" />
          <meta property="og:description" content="Need a smog check or auto repair in Temecula? We offer emissions testing, oil changes, diagnostics, and maintenance for all vehicles, including high-end cars. Visit Advanced Smog today!" />
          <meta property="og:image" content="https://www.advancedautomotivesmog.com/assets/services.jpeg" />
          <meta property="og:url" content="https://www.advancedautomotivesmog.com/services" />
          <meta property="og:type" content="website" />
              {/* Twitter Metadata */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Smog Check & Auto Repair Services in Temecula | Advanced Smog" />
          <meta name="twitter:description" content="Fast and reliable smog check and auto repair services for all vehicles, including high-end models. Visit Advanced Smog in Temecula today!" />
          <meta name="twitter:image" content="https://www.advancedautomotivesmog.com/assets/services.jpeg" />
          <link rel="canonical" href="https://www.advancedautomotivesmog.com/services" />
        </Helmet>


      <div className="max-w-7xl w-full mx-auto text-center z-20 relative">
        <h1 className="text-4xl sm:text-5xl font-bold text-white mb-8">Learn About Our Services</h1>
        <p className="text-lg sm:text-xl text-gray-200 mb-8 leading-relaxed max-w-2xl mx-auto">
          Discover some of the essential services we offer. Browse through each one to learn more, or contact us to see how we can assist with all your car care needs.
        </p>

        {/* Services Section */}
        <div className="relative w-full flex items-center justify-center">
          {/* Left Scroll Arrow */}
          <button
            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-900 bg-opacity-80 hover:bg-gray-800 text-white p-3 rounded-full shadow-md hidden md:flex z-30"
            onClick={scrollLeft}
          >
            <FaChevronLeft size={20} />
          </button>

          {/* Scrollable Services List */}
          <div
            ref={serviceScrollRef}
            className="flex overflow-x-auto scroll-smooth gap-6 pb-4 px-6 sm:px-8 hide-scrollbar relative z-20 max-w-full"
          >
            {serviceDetails.map((service, index) => (
              <Link
                key={index}
                to={service.link}
                className="group relative rounded-xl overflow-hidden shadow-lg bg-gray-900 transition-all duration-300 hover:bg-gray-800 flex-none w-64 sm:w-72 md:w-80 lg:w-72 xl:w-64 p-4"
              >
                {/* Image section */}
                <div
                  className="w-full h-44 bg-cover bg-center rounded-xl transition-all duration-300 ease-in-out"
                  style={{ backgroundImage: `url(${service.image})` }}
                ></div>
                {/* Content Section */}
                <div className="mt-4">
                  <h3 className="text-xl sm:text-2xl font-semibold text-cyan-500 mb-3">{service.title}</h3>
                  <p className="text-gray-300 text-sm sm:text-base">{service.description}</p>
                  <p className="mt-3 text-gray-400 group-hover:text-white transition duration-300 font-semibold">Learn More</p>
                </div>
              </Link>
            ))}
          </div>

          {/* Right Scroll Arrow */}
          <button
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-900 bg-opacity-80 hover:bg-gray-800 text-white p-3 rounded-full shadow-md hidden md:flex z-30"
            onClick={scrollRight}
          >
            <FaChevronRight size={20} />
          </button>
        </div>

        {/* Contact Button */}
        <div className="my-16 text-center">
          <Link
            to="/contact"
            className="px-10 py-4 sm:px-16 sm:text-xl md:text-2xl bg-cyan-500 text-white font-bold text-lg rounded-full hover:bg-cyan-600 transition duration-300 ease-in-out"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Services;
















