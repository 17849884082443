import React from 'react';
import { Link } from 'react-router-dom';
import FI from '../../assets/Fuel.png';

const FuelInjectionService = () => {
  return (
    <section className="bg-transparent text-white pt-16 pb-12 px-4 sm:px-6 relative z-10 min-h-screen flex items-center">
      {/* Ensuring the section takes full height and content is centered */}
      <div className="max-w-7xl mx-auto bg-gray-900 rounded-lg shadow-lg text-gray-900 p-6 sm:p-12">
        <div className="flex flex-col lg:flex-row gap-12 lg:gap-16 items-center">
          
          {/* Fuel Injection Service Information Section */}
          <div className="w-full lg:w-1/2 text-center lg:text-left">
            <h2 className="text-4xl sm:text-5xl font-bold text-cyan-500 mb-4 sm:mb-6">Fuel Injection Service</h2>
            <p className="text-lg sm:text-2xl text-gray-300 mb-6 sm:mb-8">
              Restore your engine's performance and fuel efficiency with our Fuel Injection Service. We clean and maintain your fuel injectors for smoother engine operation.
            </p>

            <h3 className="text-3xl sm:text-4xl font-semibold text-gray-200 mb-3 sm:mb-4">What is Fuel Injection Service?</h3>
            <p className="text-base sm:text-xl text-gray-300 mb-4 sm:mb-6 leading-relaxed">
              Fuel injectors deliver fuel to the engine, and over time, they can get clogged, leading to poor performance and reduced fuel efficiency. Our service includes cleaning the injectors to ensure optimal engine function.
              <br /><br />
              We use advanced equipment to clean the injectors, improve fuel flow, and restore the efficiency of your engine, helping it run smoother and more efficiently.
            </p>

            <ul className="text-white list-disc pl-5 space-y-2 sm:space-y-3 text-base sm:text-xl">
              <li>Improves engine performance and fuel economy</li>
              <li>Cleans fuel injectors to restore optimal fuel flow</li>
              <li>Helps prevent long-term engine damage</li>
              <li>Boosts acceleration and reduces emissions</li>
              <li>Suitable for all types of vehicles</li>
            </ul>
          </div>

          {/* Image Section */}
          <div className="w-full lg:w-1/2 flex justify-center">
            <img
              src={FI} // Replace with the owner's image path
              alt="Owner Profile"
              className="w-full sm:w-4/5 max-w-xs sm:max-w-md h-auto object-cover rounded-lg shadow-md"
            />
          </div>
        </div>

        {/* Button Section centered horizontally below the content */}
        <div className="flex justify-center mt-6">
          <Link to="/contact" className="py-3 sm:py-4 px-10 sm:px-16 bg-cyan-500 text-white font-bold rounded-full hover:bg-cyan-600 transition duration-300 text-lg sm:text-2xl">
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default FuelInjectionService;

