import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Function to close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest("nav")) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  // Close menu when a link is clicked
  const handleNavClick = () => {
    setIsMenuOpen(false);
  };

  const navLinks = [
    { to: "/", label: "Home" },
    { to: "/services", label: "Services" },
    { to: "/blogs", label: "Blogs" },
    { to: "/contact", label: "Contact" },
    { to: "/about", label: "About" },
  ];

  return (
    <nav className="bg-gray-900 w-full flex bg-opacity-60 backdrop-blur-lg items-center justify-between p-4 fixed top-0 left-0 z-40 h-16 lg:h-16 transition-all duration-300">
      {/* Hamburger Icon (only visible on mobile) */}
      <div className="lg:hidden flex items-center z-50">
        <button onClick={toggleMenu} className="text-white" aria-label="Toggle Navigation Menu">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-10 h-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      {/* Navigation Links Section (centered on larger screens) */}
      <div className="flex-1 flex justify-center z-50 hidden lg:flex">
        <ul className="flex lg:flex-row flex-col space-x-8">
          {navLinks.map((link) => (
            <li key={link.to}>
              <NavLink
                to={link.to}
                className={({ isActive }) =>
                  isActive
                    ? "text-cyan-400 py-2 px-3 font-sans font-semibold tracking-tight border-t border-b border-cyan-400"
                    : "text-white border-t border-b border-gray-300 py-2 px-3 font-sans font-semibold tracking-tight hover:py-3 hover:px-4 hover:text-cyan-300 hover:border-cyan-600 active:py-2 active:px-3 transition-all duration-300"
                }
              >
                {link.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>

      {/* Advanced Automotive Smog Text (Always visible on mobile, hidden on large screens) */}
      <div className="text-white text-xl font-bold flex-shrink-0 ml-auto z-50 lg:hidden">
        Advanced Automotive Smog
      </div>

      {/* Dropdown Menu for Mobile */}
      <div
        className={`lg:hidden flex-1 justify-center z-50 absolute top-16 left-0 w-full bg-gray-900 bg-opacity-80 p-4 ${isMenuOpen ? "block" : "hidden"}`}
      >
        <ul className="flex flex-col space-y-6">
          {navLinks.map((link) => (
            <li key={link.to}>
              <NavLink
                to={link.to}
                onClick={handleNavClick} // Close menu when a link is clicked
                className={({ isActive }) =>
                  isActive
                    ? "text-cyan-400 py-2 px-3 font-sans font-semibold tracking-tight border-t border-b border-cyan-400 text-xl sm:text-2xl"
                    : "text-white border-t border-b border-gray-300 py-2 px-3 font-sans font-semibold tracking-tight hover:py-3 hover:px-4 hover:text-cyan-400 hover:border-cyan-400 active:py-2 active:px-3 transition-all duration-300 text-xl sm:text-2xl"
                }
              >
                {link.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;






























