import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300 py-5 w-full z-40">
      <div className="max-w-7xl mx-auto px-6 flex flex-col items-center text-center">

        {/* Social Media Links */}
        <div className="flex gap-6 mb-3">
          <a href="https://www.facebook.com/profile.php?id=61573424138548" target="_blank" rel="noopener noreferrer" className="hover:text-cyan-400 transition duration-300">
            <FaFacebook size={22} />
          </a>
          <a href="https://www.instagram.com/advautosmog/" target="_blank" rel="noopener noreferrer" className="hover:text-cyan-400 transition duration-300">
            <FaInstagram size={22} />
          </a>
          <a href="https://x.com/AdvAutoSmog" target="_blank" rel="noopener noreferrer" className="hover:text-cyan-400 transition duration-300">
            <FaTwitter size={22} />
          </a>
          <a href="https://www.linkedin.com/in/edgar-depaz-3ba172353/" target="_blank" rel="noopener noreferrer" className="hover:text-cyan-400 transition duration-300">
            <FaLinkedin size={22} />
          </a>
        </div>

        {/* Divider */}
        <div className="w-full border-t border-gray-700 my-3"></div>

        {/* Copyright & Developer Credit */}
        <p className="text-sm font-medium">
          &copy; 2025 Advanced Automotive Smog. All rights reserved.
        </p>
        <p className="text-sm font-medium mt-1">
          Website built with ❤️ by{" "}
          <a href="https://www.linkedin.com/in/elijah-soghomonian-352532323/" target="_blank" rel="noopener noreferrer" className="text-cyan-400 hover:text-cyan-300 transition duration-300 font-semibold">
            Elijah Soghomonian
          </a>
        </p>

      </div>
    </footer>
  );
};

export default Footer;








