import React from 'react';
import { Helmet } from 'react-helmet';
import shop from '../assets/shop.jpg';
import ownerImage from '../assets/1OwnerPic.jpg'; // Replace with the owner's image path

const About = () => {
  return (
    <>
      {/* React Helmet SEO */}
      <Helmet>
        <title>About Advanced Automotive Smog | Trusted Smog Experts in Temecula</title>

        <meta name="description" content="Looking for a quick, eco-friendly smog check in Temecula? Our certified technicians offer fast, affordable testing for all cars, including high-end models like Land Rover, Jaguar, and Mercedes. Visit us today!" />
        <meta name="keywords" content="smog check Temecula, certified smog check, eco-friendly vehicle inspection, California emissions test, car maintenance, vehicle emissions testing, Temecula auto repair, smog station near me, Mercedes smog check" />

        {/* Open Graph Metadata (Facebook, LinkedIn) */}
        <meta property="og:title" content="About Advanced Automotive Smog | Trusted Smog Experts in Temecula" />
        <meta property="og:description" content="Fast, honest, and eco-friendly smog inspections. We specialize in high-end cars like Land Rover, Jaguar, and Mercedes but work on all makes and models." />
        <meta property="og:image" content="https://www.advancedautomotivesmog.com/assets/about.jpeg" /> {/* Replace with actual image link */}
        <meta property="og:url" content="https://www.advancedautomotivesmog.com/about" />
        <meta property="og:type" content="website" />

        {/* Twitter Metadata */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Advanced Automotive Smog | Trusted Smog Experts in Temecula" />
        <meta name="twitter:description" content="Certified smog inspections for all vehicles, including high-end brands. Eco-friendly, fast, and reliable smog testing in Temecula." />
        <meta name="twitter:image" content="https://www.advancedautomotivesmog.com/assets/about.jpeg" /> {/* Replace with actual image link */}

        {/* Canonical URL */}
        <link rel="canonical" href="https://www.advancedautomotivesmog.com/about" />
      </Helmet>


      <section className="bg-transparent w-full min-h-screen pt-20 relative">
        <div className="max-w-7xl mx-auto px-6 lg:px-8 flex flex-col min-h-[calc(100vh-5rem)] relative">
          {/* Card Container - Our Story */}
          <div className="bg-gray-900 text-gray-100 rounded-2xl shadow-lg p-8 md:p-12 flex-grow relative z-10 mb-8">
            {/* Header */}
            <div className="text-center mb-12">
              <h1 className="text-4xl sm:text-5xl font-extrabold text-cyan-500">
                Our Story
              </h1>
              <p className="mt-4 text-gray-300 text-lg">
                Fast, honest, and eco-friendly smog inspections, done right.
              </p>
            </div>

            {/* Content Section */}
            <div className="flex flex-col lg:flex-row items-center lg:items-start gap-12">
              {/* Image */}
              <div className="lg:w-1/2 mb-8 md:mb-0">
                <img
                  src={shop}
                  alt="Smog Shop"
                  className="rounded-xl shadow-md object-cover w-full h-64 md:h-80"
                  loading="lazy"
                />
              </div>

              {/* Text */}
              <div className="lg:w-1/2">
                <h2 className="text-3xl font-bold text-gray-100">
                  Your Trusted Smog Experts
                </h2>
                <p className="mt-6 text-gray-300 leading-relaxed text-lg">
                  We’re a small, family-friendly shop based in Temecula, specializing in high-end cars such as Land Rover, Jaguar,
                  and Mercedes. However, we work on all makes and models. While smog checks are one of our specialties,
                  we offer a full range of services for your vehicle, from maintenance to repairs. Our certified technicians are dedicated
                  to making the process as simple and stress-free as possible, so you can get back on the road with confidence.
                </p>
              </div>
            </div>

            {/* New Three Equal Sections */}
            <div className="mt-12 grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* First Section */}
              <div className="bg-gray-800 text-gray-100 rounded-xl shadow-md p-8">
                <h3 className="text-2xl font-semibold text-cyan-500">Expert Technicians</h3>
                <ul className="mt-4 space-y-2 text-gray-300">
                  <li className="flex items-start gap-2">
                    <span className="text-cyan-500">●</span>  Our technicians are not only certified but also passionate about cars and their work.
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="text-cyan-500">●</span> We treat every vehicle like it’s our own—careful, thorough, and respectful.
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="text-cyan-500">●</span>  We believe in a friendly approach.
                  </li>
                </ul>
              </div>

              {/* Second Section */}
              <div className="bg-gray-800 text-gray-100 rounded-xl shadow-md p-8">
                <h3 className="text-2xl font-semibold text-cyan-500">Cutting-Edge Technology</h3>
                <ul className="mt-4 space-y-2 text-gray-300">
                  <li className="flex items-start gap-2">
                    <span className="text-cyan-500">●</span> The latest diagnostic tools and equipment.
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="text-cyan-500">●</span> Accurate results mean fewer surprises and more peace of mind for you.
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="text-cyan-500">●</span>  Technology should make things easier, and that’s exactly what it does here.
                  </li>
                </ul>
              </div>

              {/* Third Section */}
              <div className="bg-gray-800 text-gray-100 rounded-xl shadow-md p-8">
                <h3 className="text-2xl font-semibold text-cyan-500">Eco-Friendly Practices</h3>
                <ul className="mt-4 space-y-2 text-gray-300">
                  <li className="flex items-start gap-2">
                    <span className="text-cyan-500">●</span> We're dedicated to supporting our community and preserving the planet.
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="text-cyan-500">●</span> By recycling and reducing emissions, we contribute to a cleaner environment every day.
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="text-cyan-500">●</span> Driving cleaner and smarter for a sustainable future.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* New Card Container - My Story (Owner's Profile) */}
          <div className="bg-gray-900 text-gray-100 rounded-2xl shadow-lg p-8 md:p-12 flex-grow relative z-10 mb-8">
            {/* Header */}
            <div className="text-center mb-12">
              <h1 className="text-4xl sm:text-5xl font-extrabold text-cyan-500">
                My Story
              </h1>
              <p className="mt-4 text-gray-300 text-lg">
                Get to know the heart and soul behind the shop.
              </p>
            </div>

            {/* Content Section */}
            <div className="flex flex-col lg:flex-row items-center lg:items-start gap-12">
              {/* Image */}
              <div className="lg:w-1/2 mb-8 lg:mb-0">
                <img
                  src={ownerImage} // Replace with the owner's image path
                  alt="Owner Profile"
                  className="rounded-xl shadow-md object-cover w-full h-64 md:h-80"
                  loading="lazy"
                />
              </div>

              {/* Text Container */}
              <div className="lg:w-1/2 flex flex-col space-y-8">
                {/* Meet the Owner Section */}
                <div>
                  <h2 className="text-3xl font-bold text-gray-100">
                    Meet the Owner
                  </h2>
                  <p className="mt-6 text-gray-300 leading-relaxed text-lg">
                    Hi, I'm Edgar, the proud owner of Advanced Automotive Smog. I've been passionate about cars since I was a kid, and I’ve dedicated my life to ensuring that each vehicle gets the care and attention it deserves. With over 30 years of experience, my goal is to offer honest, efficient, and eco-friendly smog inspections and vehicle services. At our shop, we're not just about getting your car inspected—we're about building trust and providing a personal touch for every customer.
                  </p>
                </div>

                {/* Credentials Section */}
                <div>
                  <h2 className="text-3xl font-bold text-cyan-500">
                    Credentials
                  </h2>
                  <ul className="list-disc list-inside mt-6 text-sm sm:text-lg marker:text-cyan-500 space-y-2">
                    <li className='text-gray-200 font-bold'>ASE Master Technician certified</li>
                    <li className='text-gray-200 font-bold'>Smog and Repair License certified</li>
                    <li className='text-gray-200 font-bold'>Factory Trained by Jaguar & Land Rover</li>
                    <li className='text-gray-200 font-bold'>Electric Vehicle Approved</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;












