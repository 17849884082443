import React from 'react';
import { Link } from 'react-router-dom';
import Smoke from '../../assets/SmokeTest.jpg';

const SmokeTest = () => {
  return (
    <section className="bg-transparent text-white pt-16 pb-12 px-4 sm:px-6 relative z-10 min-h-screen flex items-center">
      {/* Ensuring the section takes full height and content is centered */}
      <div className="max-w-7xl mx-auto bg-gray-900 rounded-lg shadow-lg text-gray-900 p-6 sm:p-12">
        <div className="flex flex-col lg:flex-row gap-12 lg:gap-16 items-center">

          {/* Smoke Test Information Section */}
          <div className="w-full lg:w-1/2 text-center lg:text-left">
            <h2 className="text-4xl sm:text-5xl font-bold text-cyan-500 mb-4 sm:mb-6">Smoke Test Service</h2>
            <p className="text-lg sm:text-2xl text-gray-300 mb-6 sm:mb-8">
              Detect leaks in your vehicle's systems quickly and accurately with our advanced smoke test service. Ensure optimal performance and avoid costly repairs.
            </p>

            <h3 className="text-3xl sm:text-4xl font-semibold text-gray-200 mb-3 sm:mb-4">What is a Smoke Test?</h3>
            <p className="text-base sm:text-xl text-gray-300 mb-4 sm:mb-6 leading-relaxed">
              A smoke test is used to detect leaks in your vehicle’s engine, exhaust, or other systems. By introducing smoke into the system, we can pinpoint leaks that may affect performance.
              <br /><br />
              Our smoke test service is quick, non-invasive, and allows us to identify leaks in areas such as the intake system, exhaust, and seals to keep your vehicle running at its best.
            </p>

            <ul className="text-white list-disc pl-5 space-y-2 sm:space-y-3 text-base sm:text-xl">
              <li>Quick and accurate leak detection</li>
              <li>Advanced smoke technology for precise results</li>
              <li>Identifies leaks in exhaust, intake, and seals</li>
              <li>Minimizes engine performance issues</li>
              <li>Prevents costly repairs by finding leaks early</li>
            </ul>
          </div>

          {/* Image Section */}
          <div className="w-full lg:w-1/2 flex justify-center">
            <img
              src={Smoke} // Replace with the owner's image path
              alt="Owner Profile"
              className="w-full sm:w-4/5 max-w-xs sm:max-w-md h-auto object-cover rounded-lg shadow-md"
            />
          </div>
        </div>

        {/* Button Section centered horizontally below the content */}
        <div className="flex justify-center mt-6">
          <Link to="/contact" className="py-3 sm:py-4 px-10 sm:px-16 bg-cyan-500 text-white font-bold rounded-full hover:bg-cyan-600 transition duration-300 text-lg sm:text-2xl">
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SmokeTest;
