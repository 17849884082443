import { Link } from 'react-router-dom';
import ALLF from '../../assets/AllFactory.png';

const AllFactoryMaintenance = () => {
  return (
    <section className="bg-transparent text-white pt-12 pb-12 px-4 sm:px-6 relative z-10 min-h-screen flex items-center">
      {/* Ensuring the section takes full height and content is centered */}
      <div className="max-w-7xl mx-auto bg-gray-900 rounded-lg shadow-lg text-gray-900 p-6 sm:p-12">
        <div className="flex flex-col lg:flex-row gap-12 lg:gap-16 items-center">
          
          {/* All Factory Maintenance Information Section */}
          <div className="w-full lg:w-1/2 text-center lg:text-left">
            <h2 className="text-4xl sm:text-5xl font-bold text-cyan-500 mb-4 sm:mb-6">All Factory Maintenance Services</h2>
            <p className="text-lg sm:text-2xl text-gray-300 mb-6 sm:mb-8">
              Keep your vehicle in top condition with our expert factory maintenance services. We offer reliable inspections, repairs, and replacements based on manufacturer specifications.
            </p>

            <h3 className="text-3xl sm:text-4xl font-semibold text-gray-200 mb-3 sm:mb-4">What is Factory Maintenance Service?</h3>
            <p className="text-base sm:text-xl text-gray-300 mb-4 sm:mb-6 leading-relaxed">
              Factory maintenance ensures that your vehicle performs according to the manufacturer’s standards. Our service includes comprehensive checks, fluid changes, part replacements, and any other maintenance required to keep your vehicle running smoothly.
            </p>

            <ul className="text-white list-disc pl-5 space-y-2 sm:space-y-3 text-base sm:text-xl">
              <li>Expert factory maintenance inspections and repairs</li>
              <li>High-quality parts and fluids used in all services</li>
              <li>Adherence to manufacturer specifications</li>
              <li>Compatible with all makes and models</li>
              <li>Tips for extending the lifespan of your vehicle</li>
            </ul>
          </div>

          {/* Image Section */}
          <div className="w-full lg:w-1/2 flex justify-center">
            <img
              src={ALLF} // Replace with the owner's image path
              alt="Owner Profile"
              className="w-full sm:w-4/5 max-w-xs sm:max-w-md h-auto object-cover rounded-lg shadow-md"
            />
          </div>
        </div>

        {/* Button Section centered horizontally below the content */}
        <div className="flex justify-center mt-6">
          <Link to="/contact" className="py-3 sm:py-4 px-10 sm:px-16 bg-cyan-500 text-white font-bold rounded-full hover:bg-cyan-600 transition duration-300 text-lg sm:text-2xl">
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AllFactoryMaintenance;


