import './App.css';
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from './components/About';
import Blogs from './components/Blogs';
import Contact from './components/Contact';
import Home from './components/Home';
import Services from './components/Services';
import Navbar from './components/Navbar';
import heroImage from './assets/1heroImage.jpg'; // Import the hero image
import ScrollToTop from './components/ScrollToTop';

// Import the service pages
import OilChange from './components/servicep/OilChange';
import BrakesSuspension from './components/servicep/BrakesSuspension';
import BatteryReplacement from './components/servicep/BatteryReplacement';
import SmogEmissionsServices from './components/servicep/SmogEmissionsServices';
import ElectricalDiagnostics from './components/servicep/ElectricalDiagnostics';
import SmokeTest from './components/servicep/SmokeTest';
import AirConditioning from './components/servicep/AirConditoning';
import AllFactoryMaintenance from './components/servicep/AllFactoryMaintenance';
import TireServicesAlignment from './components/servicep/TireServiceAlignment';
import TransferCase from './components/servicep/TransferCase';
import DiffService from './components/servicep/DiffService';
import TransmissionFlush from './components/servicep/TransmissionFlush';
import ABSSystem from './components/servicep/ABS-System';
import TimingBelt from './components/servicep/TimingBelt';
import TuneUPS from './components/servicep/TuneUps';
import ParasiticDraw from './components/servicep/ParasiticDraw';
import AltReplacement from './components/servicep/AlternatorReplacement';
import RadReplacement from './components/servicep/RadiatorReplacement';
import WaterP from './components/servicep/WaterPump';
import PowerS from './components/servicep/PowerSteer';
import EngineFlush from './components/servicep/EngineFlush';
import FuelInjection from './components/servicep/FuelInjection';
import BrakeFlush from './components/servicep/BrakeFlush';
import CoolantFlush from './components/servicep/CoolantFlush';

// Import the Footer component
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <ScrollToTop />

      {/* Wrapper that ensures full-screen background */}
      <div className="relative w-full min-h-screen flex flex-col">
        
        {/* 🔥 FIXED: Overlay now remains in place while scrolling */}
        <div 
          className="fixed top-0 left-0 w-full h-full z-[-1]" 
          style={{ 
            backgroundImage: `url(${heroImage})`, 
            backgroundSize: 'cover', 
            backgroundPosition: 'center top', 
            backgroundAttachment: 'fixed', 
            backgroundRepeat: 'no-repeat',
          }}
        ></div>

        {/* 🔥 FIXED: Overlay is now `fixed` so it doesn't scroll away */}
        <div className="fixed inset-0 w-full h-full bg-black opacity-85 z-[-1]"></div>

        {/* Content */}
        <div className="relative z-10 flex-grow min-h-screen flex flex-col">
          <Navbar />
          <div className="flex-grow w-full">
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/services' element={<Services />} />
              <Route path='/blogs' element={<Blogs />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/about' element={<About />} />

              {/* Service Pages */}
              <Route path='/services/tire-alignment' element={<TireServicesAlignment />} />
              <Route path='/services/oil-change' element={<OilChange />} />
              <Route path='/services/brake-suspension' element={<BrakesSuspension />} />
              <Route path='/services/battery-replacement' element={<BatteryReplacement />} />
              <Route path='/services/smog-emissions-services' element={<SmogEmissionsServices />} />
              <Route path='/services/electrical-diagnostics' element={<ElectricalDiagnostics />} />
              <Route path='/services/smoke-test' element={<SmokeTest />} />
              <Route path='/services/air-conditioning' element={<AirConditioning />} />
              <Route path='/services/all-factory' element={<AllFactoryMaintenance />} />
              <Route path='/services/transfer-case' element={<TransferCase />} />
              <Route path='/services/diff-service' element={<DiffService />} />
              <Route path='/services/transmission-flush' element={<TransmissionFlush />} />
              <Route path='/services/abs-system' element={<ABSSystem />} />
              <Route path='/services/timing-belt-replacement' element={<TimingBelt />} />
              <Route path='/services/tune-ups' element={<TuneUPS />} />
              <Route path='/services/parasitic-draw' element={<ParasiticDraw />} />
              <Route path='/services/alternator-replacement' element={<AltReplacement />} />
              <Route path='/services/radiator-replacement' element={<RadReplacement />} />
              <Route path='/services/water-pump' element={<WaterP />} />
              <Route path='/services/power-steering-flush' element={<PowerS />} />
              <Route path='/services/engine-flush' element={<EngineFlush />} />
              <Route path='/services/fuel-injection' element={<FuelInjection />} />
              <Route path='/services/brake-flush' element={<BrakeFlush />} />
              <Route path='/services/coolant-flush' element={<CoolantFlush />} />
            </Routes>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;


