import React from "react";
import { Link } from "react-router-dom"; // Make sure Link is imported for routing
import { Helmet } from "react-helmet"; // Import Helmet for SEO purposes

const Home = () => {
    // Shortened story
    const shortStory = "With Over 30 years of Experience in Auto Services Work";

    return (
        <>
            {/* SEO Meta Tags using React Helmet */}
            <Helmet>
                <title>Advanced Automotive Smog | Smog Check & Auto Repair in Temecula</title>

                <meta name="description" content="Looking for a fast & reliable smog check in Temecula? Advanced Automotive Smog offers expert emissions testing, diagnostics, factory maintenance, and more. Visit us today!" />
                <meta name="keywords" content="smog check near me, smog check Temecula, emissions test Temecula, auto repair shop, vehicle diagnostics, brake repair, AC repair, best car maintenance, high-end car smog testing, smog test for luxury vehicles" />

                {/* Open Graph Metadata (Facebook, LinkedIn) */}
                <meta property="og:title" content="Advanced Automotive Smog | Smog Check & Auto Repair in Temecula" />
                <meta property="og:description" content="Need a quick & reliable smog check? Advanced Automotive Smog provides expert emissions testing and auto repair services in Temecula. Visit us today!" />
                <meta property="og:image" content="https://www.advancedautomotivesmog.com/assets/home.jpeg" />
                <meta property="og:url" content="https://www.advancedautomotivesmog.com/" />
                <meta property="og:type" content="website" />

                {/* Twitter Metadata */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Advanced Automotive Smog | Smog Check & Auto Repair in Temecula" />
                <meta name="twitter:description" content="Trusted smog check & auto repair services in Temecula. Fast emissions testing, diagnostics, and maintenance. Visit Advanced Automotive Smog today!" />
                <meta name="twitter:image" content="https://www.advancedautomotivesmog.com/assets/home.jpeg" />

                {/* Canonical URL */}
                <link rel="canonical" href="https://www.advancedautomotivesmog.com/" />
            </Helmet>


            <div className="pt-[120px] pb-12 p-6 bg-cover bg-center">
                {/* Container for Sections */}
                <div className="grid grid-cols-1 2xl:grid-cols-2 gap-8">

                    {/* Left Section - Transparent */}
                    <div className="flex justify-center items-start bg-transparent p-6">
                        <div className="text-center text-white w-full max-w-3xl z-10">
                            <h1 className="text-4xl sm:5xl md:text-6xl lg:text-7xl font-bold mb-10 leading-tight">
                                Welcome to Advanced Automotive Smog
                            </h1>

                            {/* First paragraph */}
                            <p className="text-xl md:text-2xl lg:text-3xl font-semibold mb-0">
                                {shortStory}
                            </p>

                            {/* Second paragraph (smaller font size) */}
                            <p className="text-md md:text-lg lg:text-xl font-thin mb-4">
                            Value is built on trust—trust from your customers, trust in your team, your strategies, and your systems. And, above all, it’s about consistently delivering on what’s expected.
                            </p>

                            {/* Contact Us Button */}
                            <div className="text-center mt-16 w-full pl-6">
                                <Link
                                    to="/contact"
                                    className=" mr-4 px-10 py-4 sm:px-16 bg-cyan-500 sm:text-xl md:text-2xl text-white font-bold text-lg rounded-full hover:bg-cyan-600 transition duration-300 ease-in-out"
                                >
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>


                    {/* Right Section - Frosted Glass Effect with Cards */}
                    <div className="flex justify-center items-start bg-transparent p-6 backdrop-blur-lg bg-opacity-40 rounded-lg shadow-xl">
                        <div className="w-full max-w-5xl">

                            <h2 className="text-3xl font-semibold mb-6 text-center text-white">Our Services</h2>

                            {/* Service Cards Grid */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">

                                {/* Card - Smog & Emissions Services */}
                                <Link
                                    to="/services/smog-emissions-services"
                                    className="relative group bg-gray-900 p-6 rounded-lg shadow-lg overflow-hidden cursor-pointer flex flex-col justify-between transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-800"
                                >
                                    <div className="absolute inset-0 transition"></div>
                                    <div className="z-10 text-center">
                                        <i className="text-5xl text-white fas fa-gas-pump mb-4"></i>
                                        <h3 className="text-xl font-semibold mb-2 text-cyan-500 group-hover:text-cyan-400">Smog & Emissions Services</h3>
                                        <p className="text-lg text-white mb-4">Smog checks, emissions diagnostics, and repairs.</p>
                                        <p className="text-gray-400 group-hover:text-white font-semibold transition-colors duration-300">Learn More</p>
                                    </div>
                                </Link>

                                {/* Card - All Factory Maintenance */}
                                <Link
                                    to="/services/all-factory"
                                    className="relative group bg-gray-900 p-6 rounded-lg shadow-lg overflow-hidden cursor-pointer flex flex-col justify-between transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-800"
                                >
                                    <div className="absolute inset-0 bg-gray-900 group-hover:opacity-40 transition"></div>
                                    <div className="z-10 text-center">
                                        <i className="text-5xl text-white fas fa-wrench mb-4"></i>
                                        <h3 className="text-xl font-semibold mb-2 text-cyan-500 group-hover:text-cyan-400">All Factory Maintenance</h3>
                                        <p className="text-lg text-white mb-4">Comprehensive factory maintenance services for optimal vehicle performance.</p>
                                        <p className="text-gray-400 group-hover:text-white font-semibold transition-colors duration-300">Learn More</p>
                                    </div>
                                </Link>

                                {/* Card - Electrical & Diagnostics Services */}
                                <Link
                                    to="/services/electrical-diagnostics"
                                    className="relative group bg-gray-900 p-6 rounded-lg shadow-lg overflow-hidden cursor-pointer flex flex-col justify-between transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-800"
                                >
                                    <div className="absolute inset-0 bg-gray-900 group-hover:opacity-40 transition"></div>
                                    <div className="z-10 text-center">
                                        <i className="text-5xl text-white fas fa-plug mb-4"></i>
                                        <h3 className="text-xl font-semibold mb-2 text-cyan-500 group-hover:text-cyan-400">Electrical & Diagnostics</h3>
                                        <p className="text-lg text-white mb-4">Check engine light, alternator repair, diagnostics for modern vehicles.</p>
                                        <p className="text-gray-400 group-hover:text-white font-semibold transition-colors duration-300">Learn More</p>
                                    </div>
                                </Link>

                                {/*bottom three cards*/}
                                <Link
                                    to="/services/air-conditioning"
                                    className="relative group bg-gray-900 p-6 rounded-lg shadow-lg overflow-hidden cursor-pointer flex flex-col justify-between transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-800"
                                >
                                    <div className="absolute inset-0 bg-gray-900 group-hover:opacity-40 transition"></div>
                                    <div className="z-10 text-center">
                                        <i className="text-5xl text-white fas fa-cogs mb-4"></i>
                                        <h3 className="text-xl font-semibold mb-2 text-cyan-500 group-hover:text-cyan-400">Air Conditioning</h3>
                                        <p className="text-lg text-white mb-4">AC repairs, refrigerant recharges, and system maintenance.</p>
                                        <p className="text-gray-400 group-hover:text-white font-semibold transition-colors duration-300">Learn More</p>
                                    </div>
                                </Link>

                                {/* Card - Suspension & brakes Services */}
                                <Link
                                    to="/services/brake-suspension"
                                    className="relative group bg-gray-900 p-6 rounded-lg shadow-lg overflow-hidden cursor-pointer flex flex-col justify-between transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-800"
                                >
                                    <div className="absolute inset-0 bg-gray-900 group-hover:opacity-40 transition"></div>
                                    <div className="z-10 text-center">
                                        <i className="text-5xl text-white fas fa-car-crash mb-4"></i>
                                        <h3 className="text-xl font-semibold mb-2 text-cyan-500 group-hover:text-cyan-400">Brakes & Suspension</h3>
                                        <p className="text-lg text-white mb-4">Brake repairs, shock replacements, and suspension services.</p>
                                        <p className="text-gray-400 group-hover:text-white font-semibold transition-colors duration-300">Learn More</p>
                                    </div>
                                </Link>

                                {/* Card - Tire Services/ Alignment */}
                                <Link
                                    to="/services/tire-alignment"
                                    className="relative group bg-gray-900 p-6 rounded-lg shadow-lg overflow-hidden cursor-pointer flex flex-col justify-between transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-800"
                                >
                                    <div className="absolute inset-0 bg-gray-900 group-hover:opacity-40 transition"></div>
                                    <div className="z-10 text-center">
                                        <i className="text-5xl text-white fas fa-tire-flat mb-4"></i>
                                        <h3 className="text-xl font-semibold mb-2 text-cyan-500 group-hover:text-cyan-400">Tire Services / Alignment</h3>
                                        <p className="text-lg text-white mb-4">Tire services, alignments, rotations, balancing, and replacements</p>
                                        <p className="text-gray-400 group-hover:text-white font-semibold transition-colors duration-300">Learn More</p>
                                    </div>
                                </Link>

                            </div>

                            {/* Services Button */}
                            <div className="text-center mt-10">
                                <Link
                                    to="/services"
                                    className="px-8 py-4 sm:text-xl md:text-2xl  bg-cyan-500 text-white font-bold text-lg rounded-full hover:bg-cyan-600 transition duration-300 ease-in-out"
                                >
                                    View All Services
                                </Link>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default Home;




