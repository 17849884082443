import React from 'react';
import { Link } from 'react-router-dom';
import ALT from '../../assets/Alternator.png';

const AlternatorReplacement = () => {
  return (
    <section className="bg-transparent text-white pt-16 pb-12 px-4 sm:px-6 relative z-10 min-h-screen flex items-center">
      {/* Ensuring the section takes full height and content is centered */}
      <div className="max-w-7xl mx-auto bg-gray-900 rounded-lg shadow-lg text-gray-900 p-6 sm:p-12">
        <div className="flex flex-col lg:flex-row gap-12 lg:gap-16 items-center">

          {/* Alternator Information Section */}
          <div className="w-full lg:w-1/2 text-center lg:text-left">
            <h2 className="text-4xl sm:text-5xl font-bold text-cyan-500 mb-4 sm:mb-6">Alternator Replacement</h2>
            <p className="text-lg sm:text-2xl text-gray-300 mb-6 sm:mb-8">
              If your car’s electrical system is malfunctioning, it could be due to a faulty alternator. We offer professional alternator replacement to keep your vehicle’s power running smoothly.
            </p>

            <h3 className="text-3xl sm:text-4xl font-semibold text-gray-200 mb-3 sm:mb-4">What is Alternator Replacement?</h3>
            <p className="text-base sm:text-xl text-gray-300 mb-4 sm:mb-6 leading-relaxed">
              The alternator generates power to charge the battery and supply energy to the car’s electrical systems. Over time, the alternator can wear out or malfunction, leading to battery drain or electrical failures.
              <br /><br />
              Our service includes a full alternator inspection, replacement with a high-quality part, and professional installation. We ensure your vehicle's electrical system is functioning properly to prevent future breakdowns.
            </p>

            <ul className="text-white list-disc pl-5 space-y-2 sm:space-y-3 text-base sm:text-xl">
              <li>Full alternator diagnostics and testing</li>
              <li>High-quality replacement parts for all vehicles</li>
              <li>Expert installation and inspection</li>
              <li>Warranty on parts and labor</li>
              <li>Prevention of electrical system issues</li>
            </ul>
          </div>

          {/* Image Section */}
          <div className="w-full lg:w-1/2 flex justify-center">
            <img
              src={ALT} // Replace with the owner's image path
              alt="Owner Profile"
              className="w-full sm:w-4/5 max-w-xs sm:max-w-md h-auto object-cover rounded-lg shadow-md"
            />
          </div>
        </div>

        {/* Button Section centered horizontally below the content */}
        <div className="flex justify-center mt-6">
          <Link to="/contact" className="py-3 sm:py-4 px-10 sm:px-16 bg-cyan-500 text-white font-bold rounded-full hover:bg-cyan-600 transition duration-300 text-lg sm:text-2xl">
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AlternatorReplacement;

