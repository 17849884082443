import React from 'react';
import { Helmet } from 'react-helmet';

const Videos = () => {
  return (
    <section className="bg-transparent w-full min-h-screen pt-20 relative">
      {/* SEO Meta Tags with Helmet */}
      <Helmet>
          <title>Smog Check & Car Maintenance Blog | Advanced Automotive Smog</title>

          <meta name="description" content="Stay updated with expert tips on smog checks, car maintenance, emissions tests, and eco-friendly driving. Read our latest blog posts today!" />
          <meta name="keywords" content="smog check blog, car maintenance tips, emissions test guide, how to pass a smog test, auto repair insights, eco-friendly car tips, DIY car care, vehicle diagnostics blog, smog laws California" />

          {/* Open Graph Metadata (Facebook, LinkedIn) */}
          <meta property="og:title" content="Smog Check & Car Maintenance Blog | Advanced Automotive Smog" />
          <meta property="og:description" content="Stay updated with expert tips on smog checks, car maintenance, emissions tests, and eco-friendly driving. Read our latest blog posts today!" />
          <meta property="og:image" content="https://www.advancedautomotivesmog.com/assets/blogs.jpeg" />
          <meta property="og:url" content="https://www.advancedautomotivesmog.com/blogs" />
          <meta property="og:type" content="website" />

          {/* Twitter Metadata */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Smog Check & Car Maintenance Blog | Advanced Automotive Smog" />
          <meta name="twitter:description" content="Expert-led insights on smog checks, emissions testing, and car maintenance. Stay informed with our latest blog posts." />
          <meta name="twitter:image" content="https://www.advancedautomotivesmog.com/assets/blogs.jpeg" />

          {/* Canonical URL */}
          <link rel="canonical" href="https://www.advancedautomotivesmog.com/blogs" />
      </Helmet>


      <div className="max-w-7xl mx-auto px-6 lg:px-8 flex flex-col min-h-[calc(100vh-5rem)] relative">
        {/* Video Section Container */}
        <div className="bg-transparent p-8 md:p-12 flex-grow relative z-10 mb-8 backdrop-blur-lg bg-opacity-30 rounded-xl shadow-lg">
          {/* Video Section Header */}
          <div className="text-center mb-12">
            <h1 className="text-4xl font-extrabold text-cyan-500">
              Latest Videos
            </h1>
            <p className="mt-4 text-gray-200 text-lg md:text-xl">
              Stay informed with our latest tutorials and insights on smog checks and car maintenance.
            </p>
          </div>

          {/* Video Cards Layout */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* Video 1 */}
            <div className="bg-transparent p-6 md:p-8 rounded-xl shadow-sm backdrop-blur-lg bg-opacity-30">
              <img
                src="https://img.youtube.com/vi/aRjAKPJAHqw/maxresdefault.jpg" // Replace with actual thumbnail URL
                alt="How Smog Checks Work"
                className="rounded-xl shadow-md w-full h-48 md:h-72 object-cover"
              />
              <h3 className="text-xl md:text-2xl font-semibold text-cyan-500 mt-4 md:mt-6">
                Tour of Advanced Automotive Smog Shop!
              </h3>
              <p className="text-gray-300 mt-4 text-sm md:text-base">
                Take a Tour of the Shop at Advanced Automotive Smog and Learn Some Fun Facts!
              </p>
              <a href="https://www.youtube.com/embed/aRjAKPJAHqw" target="_blank" rel="noreferrer" className="text-cyan-500 hover:text-cyan-700 transition-all duration-200 mt-4 inline-block text-sm md:text-base">
                Watch Video
              </a>
            </div>

            {/* Video 2 */}
            <div className="bg-transparent p-6 md:p-8 rounded-xl shadow-sm backdrop-blur-lg bg-opacity-30">
              <img
                src="https://img.youtube.com/vi/HvmH8AMTef4/maxresdefault.jpg" // Replace with actual thumbnail URL
                alt="Tips for Passing Smog Test"
                className="rounded-xl shadow-md w-full h-48 md:h-72 object-cover"
              />
              <h3 className="text-xl md:text-2xl font-semibold text-cyan-500 mt-4 md:mt-6">
                Checking the Oil.
              </h3>
              <p className="text-gray-300 mt-4 text-sm md:text-base">
                Learn How to Check Your Oil and Get Some Pro Tips!
              </p>
              <a href="https://www.youtube.com/embed/HvmH8AMTef4" target="_blank" rel="noreferrer" className="text-cyan-500 hover:text-cyan-700 transition-all duration-200 mt-4 inline-block text-sm md:text-base">
                Watch Video
              </a>
            </div>

            {/* Video 3 */}
            <div className="bg-transparent p-6 md:p-8 rounded-xl shadow-sm backdrop-blur-lg bg-opacity-30">
              <img
                src="https://img.youtube.com/vi/tsht3WoTZ98/maxresdefault.jpg" // Replace with actual thumbnail URL
                alt="How to Prepare for a Smog Test"
                className="rounded-xl shadow-md w-full h-48 md:h-72 object-cover"
              />
              <h3 className="text-xl md:text-2xl font-semibold text-cyan-500 mt-4 md:mt-6">
                Torque The Tire!
              </h3>
              <p className="text-gray-300 mt-4 text-sm md:text-base">
                Learn How to Properly Torque Your Tires with a Torque Wrench, Right Here at Advanced Automotive Smog!
              </p>
              <a href="https://www.youtube.com/embed/tsht3WoTZ98" target="_blank" rel="noreferrer" className="text-cyan-500 hover:text-cyan-700 transition-all duration-200 mt-4 inline-block text-sm md:text-base">
                Watch Video
              </a>
            </div>

            {/* Video 4 */}
            <div className="bg-transparent p-6 md:p-8 rounded-xl shadow-sm backdrop-blur-lg bg-opacity-30">
              <img
                src="https://img.youtube.com/vi/MBg2_AhBizM/maxresdefault.jpg" // Replace with actual thumbnail URL
                alt="How to Check Oil"
                className="rounded-xl shadow-md w-full h-48 md:h-72 object-cover"
              />
              <h3 className="text-xl md:text-2xl font-semibold text-cyan-500 mt-4 md:mt-6">
                Washer Fluid Maintenance Made Easy
              </h3>
              <p className="text-gray-300 mt-4 text-sm md:text-base">
                Learn How to Check Your Washer Fluid Here at Advanced Auto Smog!
              </p>
              <a href="https://www.youtube.com/embed/MBg2_AhBizM" target="_blank" rel="noreferrer" className="text-cyan-500 hover:text-cyan-700 transition-all duration-200 mt-4 inline-block text-sm md:text-base">
                Watch Video
              </a>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Videos;

