import React from 'react';
import { Link } from 'react-router-dom';
import Power from '../../assets/Power.png';

const PowerSteeringFlush = () => {
  return (
    <section className="bg-transparent text-white pt-16 pb-12 px-4 sm:px-6 relative z-10 min-h-screen flex items-center">
      {/* Ensuring the section takes full height and content is centered */}
      <div className="max-w-7xl mx-auto bg-gray-900 rounded-lg shadow-lg text-gray-900 p-6 sm:p-12">
        <div className="flex flex-col lg:flex-row gap-12 lg:gap-16 items-center">
          
          {/* Power Steering Flush Information Section */}
          <div className="w-full lg:w-1/2 text-center lg:text-left">
            <h2 className="text-4xl sm:text-5xl font-bold text-cyan-500 mb-4 sm:mb-6">Power Steering Flush</h2>
            <p className="text-lg sm:text-2xl text-gray-300 mb-6 sm:mb-8">
              Keep your steering system smooth and responsive with a professional power steering flush service. We ensure your system stays clean and efficient.
            </p>

            <h3 className="text-3xl sm:text-4xl font-semibold text-gray-200 mb-3 sm:mb-4">What is Power Steering Flush?</h3>
            <p className="text-base sm:text-xl text-gray-300 mb-4 sm:mb-6 leading-relaxed">
              Over time, power steering fluid degrades, causing friction and wear on components. A flush removes the old fluid and replaces it with fresh fluid, keeping the system running smoothly.
              <br /><br />
              Our service includes a full flush of the power steering system, removal of old fluid, and refill with high-quality fluid, ensuring your steering system operates at peak performance.
            </p>

            <ul className="text-white list-disc pl-5 space-y-2 sm:space-y-3 text-base sm:text-xl">
              <li>Comprehensive power steering fluid flush</li>
              <li>Prevents system wear and enhances steering performance</li>
              <li>High-quality fluid replacement</li>
              <li>Helps prevent steering noise and difficulty</li>
              <li>Increases the lifespan of power steering components</li>
            </ul>
          </div>

          {/* Image Section */}
          <div className="w-full lg:w-1/2 flex justify-center">
            <img
              src={Power} // Replace with the owner's image path
              alt="Owner Profile"
              className="w-full sm:w-4/5 max-w-xs sm:max-w-md h-auto object-cover rounded-lg shadow-md"
            />
          </div>
        </div>

        {/* Button Section centered horizontally below the content */}
        <div className="flex justify-center mt-6">
          <Link to="/contact" className="py-3 sm:py-4 px-10 sm:px-16 bg-cyan-500 text-white font-bold rounded-full hover:bg-cyan-600 transition duration-300 text-lg sm:text-2xl">
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default PowerSteeringFlush;

