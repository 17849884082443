import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
          <div className="text-center p-6 bg-gray-800 rounded-lg shadow-lg border border-cyan-500 max-w-lg">
            <h1 className="text-4xl font-bold text-cyan-500">We'll Be Right Back</h1>
            <p className="mt-2 text-gray-300">
              We're currently performing maintenance or resolving an issue.
              Your patience is appreciated, and we'll have things back up and running soon.
            </p>
            <button
              className="mt-4 px-6 py-2 bg-cyan-500 text-white font-semibold rounded-lg hover:bg-cyan-600 transition duration-300"
              onClick={() => window.location.reload()}
            >
              Try Again
            </button>
            <p className="mt-2 text-sm text-gray-400">If the issue persists, please check back later.</p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

