import React from 'react';
import { Link } from 'react-router-dom';
import Cool from '../../assets/coolant.jpg';

const CoolantFlush = () => {
  return (
    <section className="bg-transparent text-white pt-16 pb-12 px-4 sm:px-6 relative z-10 min-h-screen flex items-center">
      {/* Ensuring the section takes full height and content is centered */}
      <div className="max-w-7xl mx-auto bg-gray-900 rounded-lg shadow-lg text-gray-900 p-6 sm:p-12">
        <div className="flex flex-col lg:flex-row gap-12 lg:gap-16 items-center">
          
          {/* Coolant Flush Information Section */}
          <div className="w-full lg:w-1/2 text-center lg:text-left">
            <h2 className="text-4xl sm:text-5xl font-bold text-cyan-500 mb-4 sm:mb-6">Coolant Flush</h2>
            <p className="text-lg sm:text-2xl text-gray-300 mb-6 sm:mb-8">
              Ensure your engine stays cool and operates efficiently with a professional coolant flush service. We replace old, degraded coolant to help prevent overheating and corrosion.
            </p>

            <h3 className="text-3xl sm:text-4xl font-semibold text-gray-200 mb-3 sm:mb-4">What is Coolant Flush?</h3>
            <p className="text-base sm:text-xl text-gray-300 mb-4 sm:mb-6 leading-relaxed">
              Over time, coolant can become contaminated, reducing its effectiveness. A coolant flush removes the old fluid and replaces it with fresh coolant to maintain engine performance and prevent overheating.
              <br /><br />
              Our service keeps your vehicle’s cooling system working efficiently, helping to avoid costly engine damage and keeping your engine running smoothly.
            </p>

            <ul className="text-white list-disc pl-5 space-y-2 sm:space-y-3 text-base sm:text-xl">
              <li>Prevents overheating and engine damage</li>
              <li>Protects against corrosion and rust build-up</li>
              <li>Enhances the efficiency of your cooling system</li>
              <li>Compatible with all makes and models</li>
              <li>Expert installation and fluid replacement</li>
            </ul>
          </div>

          {/* Image Section */}
          <div className="w-full lg:w-1/2 flex justify-center">
            <img
              src={Cool} // Replace with the owner's image path
              alt="Owner Profile"
              className="w-full sm:w-4/5 max-w-xs sm:max-w-md h-auto object-cover rounded-lg shadow-md"
            />
          </div>
        </div>

        {/* Button Section centered horizontally below the content */}
        <div className="flex justify-center mt-6">
          <Link to="/contact" className="py-3 sm:py-4 px-10 sm:px-16 bg-cyan-500 text-white font-bold rounded-full hover:bg-cyan-600 transition duration-300 text-lg sm:text-2xl">
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default CoolantFlush;

