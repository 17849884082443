import React from 'react';
import { Link } from 'react-router-dom';
import Smog from '../../assets/smogT.png'; // Replace with the owner's image path

const SmogEmissions = () => {
  return (
    <section className="bg-transparent text-white pt-12 pb-12 px-4 sm:px-6 relative z-10 min-h-screen flex items-center">
      {/* Ensuring the section takes full height and content is centered */}
      <div className="max-w-7xl mx-auto bg-gray-900 rounded-lg shadow-lg text-gray-900 p-6 sm:p-12">
        <div className="flex flex-col lg:flex-row gap-12 lg:gap-16 items-center">
          
          {/* Smog & Emissions Information Section */}
          <div className="w-full lg:w-1/2 text-center lg:text-left">
            <h2 className="text-4xl sm:text-5xl font-bold text-cyan-500 mb-4 sm:mb-6">Smog & Emissions Testing</h2>
            <p className="text-lg sm:text-2xl text-gray-300 mb-6 sm:mb-8">
              Stay compliant with state regulations and keep your vehicle running efficiently with our Smog & Emissions services.
            </p>

            <h3 className="text-3xl sm:text-4xl font-semibold text-gray-200 mb-3 sm:mb-4">What is Smog & Emissions Testing?</h3>
            <p className="text-base sm:text-xl text-gray-300 mb-4 sm:mb-6 leading-relaxed">
              Smog testing is essential to ensure your vehicle meets environmental standards and runs efficiently. A vehicle that fails smog testing may have engine or exhaust system issues that need to be addressed.
              <br /><br />
              Our service includes a complete inspection of your vehicle’s emissions system, a detailed report, and any necessary repairs or maintenance to help you pass the test.
            </p>

            <ul className="text-white list-disc pl-5 space-y-2 sm:space-y-3 text-base sm:text-xl">
              <li>Comprehensive smog and emissions testing</li>
              <li>State-certified emissions inspections</li>
              <li>Repair services for vehicles that fail the test</li>
              <li>Quick, efficient testing to get you back on the road</li>
              <li>Expert advice on improving your vehicle’s emissions performance</li>
            </ul>
          </div>

          {/* Image Section */}
          <div className="w-full lg:w-1/2 flex justify-center">
            <img
              src={Smog} // Replace with the owner's image path
              alt="Owner Profile"
              className="w-full sm:w-4/5 max-w-xs sm:max-w-md h-auto object-cover rounded-lg shadow-md"
            />
          </div>
        </div>

        {/* Button Section centered horizontally below the content */}
        <div className="flex justify-center mt-6">
          <Link to="/contact" className="py-3 sm:py-4 px-10 sm:px-16 bg-cyan-500 text-white font-bold rounded-full hover:bg-cyan-600 transition duration-300 text-lg sm:text-2xl">
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SmogEmissions;


