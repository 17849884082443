import React from 'react';
import { Helmet } from 'react-helmet';
import shop from '../assets/shop.jpg'; // Temporary image
import insta from '../assets/instalogo.png';
import face from '../assets/facelogo.png';
import X from '../assets/xlogo.png';
import linked from '../assets/linked.png';
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';

const Contact = () => {
  return (
    <section className="bg-gray-900 text-white pt-28 pb-12 -mb-20 px-6 relative z-10">
      {/* SEO Meta Tags with Helmet */}
      <Helmet>
        <meta name="description" content="Need a high-end smog check in Temecula? Contact Advanced Smog today for expert testing on Land Rover, BMW, and luxury vehicles. Call us or visit our shop now" />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content="smog check near me, luxury smog check Temecula, certified emissions test, Land Rover smog check, high-end vehicle smog testing, Temecula auto emissions, premium smog check, California smog test, Temecula car inspection, BMW smog check, Mercedes-Benz emissions test" />
        <meta property="og:title" content="Contact Advanced Smog - Luxury Automotive Smog Services in Temecula" />
        <meta property="og:description" content="Need a high-end smog check in Temecula? Contact Advanced Smog today for expert testing on Land Rover, BMW, and luxury vehicles. Call us or visit our shop now" />
        <meta property="og:url" content="https://www.advancedautomotivesmog.com/contact" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.advancedautomotivesmog.com/assets/contact.jpeg" /> {/* Replace with the actual image link */}
        <title>Contact Advanced Smog | High-End Smog Check in Temecula</title>
        <link rel="canonical" href="https://www.advancedautomotivesmog.com/contact" /> {/* Replace with the actual URL */}
      </Helmet>


      <div className="max-w-7xl mx-auto flex flex-col md:flex-row gap-10 md:gap-16 pb-2 relative z-20">
        {/* Temporary Image Section */}
        <div className="w-full md:w-1/2 h-72 sm:h-80 md:h-96">
          <img
            src={shop}
            alt="Advanced Smog Shop specializing in high-end automotive smog checks, including Land Rover."
            className="w-full h-full object-cover rounded-xl shadow-sm"
          />
        </div>

        {/* Contact Information Section */}
        <div className="w-full md:w-1/2 flex flex-col justify-center text-center md:text-left">
          <h2 className="text-3xl sm:text-4xl font-bold text-cyan-500 mb-4 sm:mb-6">Contact Information</h2>
          <div className="space-y-6 md:space-y-8">
            {/* Address - Clickable to Google Maps */}
            <div className="flex flex-col md:flex-row items-center gap-2 sm:gap-4">
              <FaMapMarkerAlt size={24} className="text-cyan-400" />
              <div>
                <h3 className="font-semibold text-gray-200 text-lg sm:text-xl">Address</h3>
                <a
                  href="https://www.google.com/maps?q=42103+Rio+Nedo,+Ste+108,+Temecula,+CA+92590"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-300 text-base sm:text-lg hover:text-cyan-400 transition-colors duration-200"
                >
                  42103 Rio Nedo, Ste 108, Temecula, CA 92590
                </a>
              </div>
            </div>

            {/* Phone - Clickable to Call */}
            <div className="flex flex-col md:flex-row items-center gap-2 sm:gap-4">
              <FaPhone size={24} className="text-cyan-400" />
              <div>
                <h3 className="font-semibold text-gray-200 text-lg sm:text-xl">Phone</h3>
                <a
                  href="tel:+19512961242"
                  className="text-gray-300 text-base sm:text-lg hover:text-cyan-400 transition-colors duration-200"
                >
                  +1 (951) 296-1242
                </a>
              </div>
            </div>

            {/* Email - Clickable to Open Mail */}
            <div className="flex flex-col md:flex-row items-center gap-2 sm:gap-4">
              <FaEnvelope size={24} className="text-cyan-400" />
              <div>
                <h3 className="font-semibold text-gray-200 text-lg sm:text-xl">Email</h3>
                <a
                  href="mailto:advancedautomotivesmog@gmail.com"
                  className="text-gray-300 text-base sm:text-lg hover:text-cyan-400 transition-colors duration-200"
                >
                  advancedautomotivesmog@gmail.com
                </a>
              </div>
            </div>
          </div>

          {/* Social Media Links */}
          <div className="flex justify-center md:justify-start gap-6 mt-10">
            <a href="https://www.facebook.com/profile.php?id=61573424138548" target="_blank" rel="noopener noreferrer" className="hover:scale-110 transition-transform duration-300">
              <img src={face} alt="Facebook" className="w-8 sm:w-10 h-8 sm:h-10" />
            </a>
            <a href="https://www.instagram.com/advautosmog/" target="_blank" rel="noopener noreferrer" className="hover:scale-110 transition-transform duration-300">
              <img src={insta} alt="Instagram" className="w-8 sm:w-10 h-8 sm:h-10" />
            </a>
            <a href="https://x.com/AdvAutoSmog" target="_blank" rel="noopener noreferrer" className="hover:scale-110 transition-transform duration-300">
              <img src={X} alt="Twitter" className="w-8 sm:w-10 h-8 sm:h-10" />
            </a>
            <a href="https://www.linkedin.com/in/edgar-depaz-3ba172353/" target="_blank" rel="noopener noreferrer" className="hover:scale-110 transition-transform duration-300">
              <img src={linked} alt="LinkedIn" className="w-10 sm:w-12 h-8 sm:h-10" />
            </a>
          </div>
        </div>
      </div>

      {/* Google Map Section */}
      <div className="pt-16 sm:pt-20 pb-12">
        <h2 className="text-3xl sm:text-4xl font-bold text-cyan-500 mb-6 text-center">Find Us Here</h2>
        <div className="w-full min-h-[22rem] sm:h-96 rounded-lg overflow-hidden shadow-lg"
         style={{ height: "calc(100vh - 600px)" }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12698.358178399632!2d-117.129025!3d33.533298!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80db7a447cc2cd1b%3A0x8b828a4840d7f2d5!2s42103%20Rio%20Nedo%20Ste%20108%2C%20Temecula%2C%20CA%2092590!5e0!3m2!1sen!2sus!4v1698082820607!5m2!1sen!2sus"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Location Map"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Contact;
















