import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log(`ScrollToTop triggered for: ${pathname}`);
    // Scroll the body element to the top
    document.body.scrollTo(0, 0);
    document.documentElement.scrollTo(0, 0); // For browsers that sync `html` and `body`
  }, [pathname]);

  return null; // This component doesn't render anything
};

export default ScrollToTop;
